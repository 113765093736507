body > iframe {
  display: none !important;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  text-rendering: optimizeLegibility;
}

body,
html {
  height: 100vsh;
}

body {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #282828;
  margin: 0;
  padding: 0;
  touch-action: pan-y;
}

body,
html,
select,
option {
  font-size: 24px;
}

:root {
  --topnav-height: 44px;
  --separator-color: #444;
  --topnav-button-height: 32px;
}

button,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    sans-serif;
  color: #ccc;
}

button {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin: 0;
  display: block;
  background-color: transparent;
}
button:hover {
  background-color: #222;
}

* {
  position: relative;
}

a {
  text-decoration: none;
  color: #74b9ff;
}
a::after {
  content: '';
  display: block;
  width: 100%;
  height: 6px;
  background: #74b9ff15;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -6px;
  z-index: -1;
}

a:hover {
  color: #0984e3;
}

hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.type,
textarea {
  width: calc(100vw - 32px);
  margin: 0;
  padding: 14px;
  display: block;
  top: var(--topnav-height);
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

@media screen and (min-width: 640px) {
  .type,
  textarea {
    padding: 28px;
  }
}

textarea {
  margin-top: 8px;
  transform-origin: center left;
  transform: scalex(1.01);
  word-break: break-all;
  opacity: 1;
  background-color: #0000;
  color: #f000;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  font-size: 23px;
  letter-spacing: 0;
  font-family: Courier, monospace;
  line-height: 30px;
  z-index: 100;
  resize: none;
  caret-color: white;
  outline: none;
}
textarea:focus,
textarea:active {
  outline: none;
}

textarea::selection {
  background-color: #e74c3c80;
  color: transparent;
}

.type {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.type-break {
  width: 100%;
}
svg {
  display: block;
  margin: 0;
}
.key {
  color: white;
  height: 22px;
  margin-bottom: -4px;
  margin-right: 4px;
  margin-top: 12px;
  width: 10px;
}

.unknown-char {
  display: block;
  line-height: 20px;
  font-size: 24px;
  color: red;
}

.sidebar {
  user-select: none;
  z-index: 200;
  position: fixed;

  bottom: 0;
  left: 0;
  width: 100vw;
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;
  align-items: center;
  gap: 28px;
  padding: 16px 16px 24px;
  border-left: none;
  border-top: 1px solid var(--separator-color);

  background-color: #28282880;
  backdrop-filter: blur(16px);
}

@media screen and (min-width: 1000px) {
  .sidebar {
    top: var(--topnav-height);
    right: 0;
    left: auto;
    width: 190px;
    height: calc(100vh - var(--topnav-height));
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: none;
    border-left: 1px solid var(--separator-color);
  }
}

.scroller {
  top: var(--topnav-height);
  height: calc(100vh - var(--topnav-height));
  height: calc(100svh - var(--topnav-height));
  overflow-x: hidden;
  overflow-y: scroll;
}
@media screen and (max-width: 1000px) {
  .scroller {
    top: var(--topnav-height);
    height: calc(100vh - var(--topnav-height) - 180px);
    height: calc(100svh - var(--topnav-height) - 180px);
  }
}

.jbx-tabs {
  width: 250px;
  display: flex;
  background-color: #333;
  border-radius: 6px;
  padding: 2px;
  box-shadow: inset #2224 0 2px 2px;
}
.jbx-tabs button {
  flex: 1;
  height: 40px;
  border-radius: 6px;
}
.jbx-tabs button.active {
  background-color: #444;
  box-shadow: inset #fff1 0 1px 0, rgba(0, 0, 0, 0.1) 0 1px 0;
  color: white;
  font-weight: 700;
}

.jbx-input {
  appearance: none;
  border: none;
  font-size: 16px;
  border-radius: 6px;
  padding: 6px;
  height: 40px;
  width: 100%;
  background-color: #333;
  box-shadow: inset #2224 0 2px 2px;
  color: white;
}

.editor-input {
  font-size: 44px;
  width: 64px;
  height: 64px;
  text-align: center;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  border: none;
  border-radius: 8px;
  background: #222;
  color: #fff;
  box-shadow: inset #111 0 2px 8px;
}

.editor-input-label {
  text-shadow: #0004 0 -1px 0;
}

.editor-input-container {
  height: 84px;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  gap: 4px;
  text-transform: uppercase;
  font-size: 12px;
}

.editor-char {
  font-size: 64px;
  color: #fff;
}

.editor-controls {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 16px;
  height: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.editor svg {
  pointer-events: none;
}
.editor-dot {
  cursor: pointer;
  height: 16px;
  width: 16px;
  transform: translatey(-50%) translatex(-50%);
  border-radius: 16px;
  opacity: 1;
  position: absolute;
}

.topnav {
  user-select: none;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 8px;
  z-index: 300;
  font-size: 15px;
  width: 100vw;
  height: var(--topnav-height);
  border-bottom: 1px solid var(--separator-color);

  background-color: #28282880;
  backdrop-filter: blur(16px);
}
.topnav.-responsive {
  height: auto;
  min-height: var(--topnav-height);
  flex-wrap: wrap;
}
.topnav.-responsive .-hide-on-mobile {
  display: initial !important;
}

.topnav button {
  border: none;
  font-size: 15px;
  margin: 0;
  padding: 0 16px;
}
.topnav > button {
  height: var(--topnav-height);
}
.topnav button[data-state='open'] {
  background-color: #111;
}
.topnav button[data-state='open']::after {
  content: '';
  display: block;
  border: 10px solid transparent;
  border-bottom: 10px solid #444;
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -10px;
}

.jbx-menu {
  padding: 0 10px;
  height: var(--topnav-height);
  line-height: var(--topnav-height);
  position: relative;
  cursor: pointer;
}
.jbx-menu:hover {
  background: #333;
}

.jbx-menu.-open {
  background-color: #111;
}

.jbx-menu-options {
  background: #111;
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
}

.jbx-menu-option {
  padding: 0 10px;
  border-bottom: 1px solid #333;
}

.jbx-menu-option:hover {
  background: #000;
}

.jbx-popover {
  margin: 3px;
  background-color: #444;
  border-radius: 8px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 0, rgba(16, 16, 16, 0.5) 0 4px 8px;
  overflow: hidden;
  border-bottom: 1px solid #fff1;
}
.jbx-popover > button {
  height: 40px;
  padding: 0 16px;
  border-radius: 6px;
  width: 100%;
  text-align: left;
  line-height: 1;
}
.jbx-popover .jbx-popover--content {
  padding: 8px 0;
}

.jbx-popover .jbx-popover--title {
  padding: 12px 0 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-shadow: #0001 0 -1px 0;
}

.jbx-popover .jbx-popover--title:first-child {
  padding-top: 0;
}

.jbx-popover .jbx-input {
  padding: 8px 16px;
}
.jbx-popover .jbx-input:focus {
  outline: none;
  box-shadow: #e84393 0 0 0 0.5px, #e8439340 0 0 0 3px, yellow 0 0 14px -10px;
}

.sidebar button {
  background-color: #444;
  border: none;
  padding: 0 8px;
  height: 24px;
  line-height: 1;
  margin: 0;
}
.sidebar button:hover {
  background-color: #555;
  color: #fff;
}

.-hide-on-mobile {
  display: none;
}
.-show-only-mobile {
  display: initial;
}

@media screen and (min-width: 920px) {
  .-hide-on-mobile {
    display: initial;
  }
  .-show-only-mobile {
    display: none;
  }
}
